import { Message } from 'yup/lib/types'
import { LocaleObject } from 'yup/lib/locale'

import { FormatEnum, formatNumber } from '../utils'

const withUnit = (msg: string | number, unit: string, prefix: boolean) => {
  return prefix ? `${unit}${msg}` : `${msg}${unit}`
}

const getParam = (params: { min?: number; max?: number; more?: number; less?: number }) => {
  return params.min ?? params.max ?? params.more ?? params.less!
}

type NumberMessageParams = { min: number; max: number } | { min: number } | { max: number } | { more: number } | { less: number }

const getSign = (params: NumberMessageParams) => {
  switch (true) {
    case 'min' in params:
      return '≥'

    case 'max' in params:
      return '≤'

    case 'more' in params:
      return '>'

    case 'less' in params:
      return '<'

    default:
      return ''
  }
}

export const createMsgNumber = ({
  unit = '',
  prefix = false,
  format = FormatEnum.Number,
  label = 'Value',
}: {
  unit?: string
  prefix?: boolean
  format?: FormatEnum
  label?: string
} = {},
): (params: NumberMessageParams) => Message<NumberMessageParams> => {
  return (params) => {
    if ('min' in params && 'max' in params) {
      const { min, max } = params

      const minFormatted = formatNumber(min, format)
      const maxFormatted = formatNumber(max, format)
      const formatMinValue = prefix ? `${unit}${minFormatted}` : `${minFormatted}`
      const formatMaxValue = prefix ? `${maxFormatted}` : `${maxFormatted}${unit}`

      return `${label} must be between ${formatMinValue}-${formatMaxValue}`
    }

    return `${label} must be ${getSign(params)} ${withUnit(formatNumber(getParam(params), format), unit, prefix)}`
  }
}

export const msgNumber = createMsgNumber()
export const msgPercentage = createMsgNumber({ unit: '%' })
export const msgCurrency = createMsgNumber({ unit: '$', prefix: true })
export const msgRequired = () => 'Required field'
export const defaultMessages: LocaleObject = {
  mixed: {
    default: 'Invalid value',
    notType: 'Incorrect type',
    required: msgRequired,
    oneOf: ({ value }) => value ? 'Invalid value' : msgRequired(),
    notOneOf: ({ value }) => value ? 'Invalid value' : msgRequired(),
  },
  string: {
    length: ({ length }) => `Must be exactly ${length} characters`,
    min: ({ min }) => min === 1 ? msgRequired() : `Must be at least ${min} characters`,
    max: ({ max }) => `Must be at most ${max} characters`,
    url: 'Must be a valid url',
    email: 'Must be a valid e-mail address',
  },
  boolean: {
    isValue: ({ value }) => value ? msgRequired() : 'Must not be selected',
  },
  number: {
    min: createMsgNumber(),
    max: createMsgNumber(),
    moreThan: createMsgNumber(),
    lessThan: createMsgNumber(),
  },
  array: {
    length: ({ length }) => length === 1 ? msgRequired() : `Select ${length} options`,
    min: ({ min }) => min === 1 ? msgRequired() : `Select at least ${min} options`,
    max: ({ max }) => `Select at most ${max} options`,
  },
}
